<template>
  <div id="employeeUpdate" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base" v-if="user">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >ACTUALIZACIÓN DE EMPLEADO</v-row
      >
      <v-container fluid class="addForms" style="margin-top: 2% !important">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol formularios">
            <v-row>
              <div class="form-group">
                <label for="level">No. de Empleado</label>
                <input
                  type="text"
                  name="employeeNumber"
                  id="employeeNumber"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.id"
                  required
                  maxlength="20"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="name">Nombre (s)</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.nombre"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  @blur="
                    nombreCompuesto();
                    answer();
                  "
                />
              </div>
              <div class="form-group">
                <label for="surname">Apellido Paterno</label>
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.apellidoPaterno"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  @blur="
                    quitaArticulosPaterno();
                    answer();
                  "
                />
              </div>
              <div class="form-group">
                <label for="lastName">Apellido Materno</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.apellidoMaterno"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  @blur="
                    quitaArticulosMaterno();
                    answer();
                  "
                />
              </div>
              <div class="form-group">
                <label for="date">Fecha Nacimiento</label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.fechaNacimiento"
                  required
                  maxlength="20"
                  @blur="answer()"
                />
              </div>
              <div class="form-group">
                <label for="date">Género</label>
                <select name="job" id="job" v-model="dataUser.generoId">
                  <option disabled value="0">Seleccione</option>
                  <option
                    v-for="gen in genders"
                    :key="gen.id"
                    v-bind:value="gen.id"
                  >
                    {{ gen.nombreGenero }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="curp">CURP</label>
                <input
                  type="text"
                  name="curp"
                  id="curp"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  required
                  v-model="dataUser.curp"
                  maxlength="18"
                />
                <p
                  style="color: red; margin-left: 160px !important"
                  v-if="errorCurp"
                >
                  *Ingresa un CURP válido
                </p>
              </div>
              <div class="form-group">
                <label for="rfc">RFC</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.rfc"
                  required
                  maxlength="13"
                  @keypress="validaRfc($event)"
                />
              </div>
              <div class="form-group">
                <label for="personal">Telefóno Personal</label>
                <input
                  type="text"
                  name="personal"
                  id="personal"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.telefonoCelular"
                  required
                  maxlength="10"
                />
              </div>
              <div class="form-group">
                <label for="correo">Correo electrónico</label>
                <input
                  type="text"
                  name="correo"
                  id="correo"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.email"
                  required                  
                />
              </div>
              <div class="form-group">
                <label for="imss">IMSS</label>
                <input
                  type="text"
                  name="imss"
                  id="imss"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.nss"
                  required
                  maxlength="11"
                  @keypress="isNumber($event)"
                />
              </div>
              <div class="form-group">
                <label for="job">Puesto</label>
                <select name="job" id="job" v-model="dataUser.puestoId">
                  <option disabled value="0">Selecciona Puesto</option>
                  <option
                    v-for="job in jobs"
                    :key="job.id"
                    v-bind:value="job.id"
                  >
                    {{ job.nombrePuesto }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="select2">Area/Departamento</label>
                <select name="select2" id="select2" v-model="dataUser.areaId">
                  <option disabled value="0">Selecciona Area/Depto</option>
                  <option
                    v-for="area in areas"
                    :key="area.id"
                    v-bind:value="area.id"
                  >
                    {{ area.nombreArea }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="select2">Sucursal</label>
                <select
                  name="select2"
                  id="select2"
                  v-model="dataUser.sucursalId"
                  @change="numeroSucursal()"
                >
                  <option disabled value="0">Selecciona Sucursal</option>
                  <option
                    v-for="branch in branches"
                    :key="branch.id"
                    v-bind:value="branch.id"
                  >
                    {{ branch.nombreSucursal }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="oficina">Telefóno</label>
                <input
                  type="text"
                  name="oficina"
                  id="oficina"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="telefonoSucursal"
                  disabled
                  maxlength="10"
                />
              </div>
              <div class="form-group">
                <label for="ext">Extensión</label>
                <input
                  type="text"
                  name="ext"
                  id="ext"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.telefonoExtension"
                  required
                  maxlength="6"
                />
              </div>
              <div class="form-group">
                <label for="dateAdmission">Fecha Admisión</label>
                <input
                  type="date"
                  name="dateAdmission"
                  id="dateAdmission"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.fechaAdmision"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="sueldo">Sueldo de nomina</label>
                <input
                  type="text"
                  name="sueldo"
                  id="sueldo"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.sueldo"
                  required
                  maxlength="5"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-5"
            align-self="center"
          >
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresar()">
                Cancelar
              </button>
              <button class="botonAmarilloDerecho" @click="validacion()">
                Continuar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="base" v-if="dataBank">
      <v-row no-gutters class="titulo ml-10 mt-5">DATOS BANCARIOS</v-row>
      <v-container fluid class="addCategory">
        <v-row no-gutters justify="end" class="formulariosTarget">
          <v-col cols="12" lg="5" md="4" sm="6" class="ml-5">
            <v-row>
              <div class="form-group mt-2">
                <label for="bank">Banco</label>
                <select
                  name="banck"
                  id="bank"
                  v-model="dataUser.datosBancoEmpleado.bancoId"
                >
                  <option disabled value="0">Selecciona Banco</option>
                  <option
                    v-for="bank in banks"
                    :key="bank.id"
                    v-bind:value="bank.id"
                  >
                    {{ bank.nombreCorto }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="count">Cuenta</label>
                <input
                  type="text"
                  name="count"
                  id="count"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.datosBancoEmpleado.cuenta"
                  required
                  maxlength="10"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="key">Cuenta clave</label>
                <input
                  type="text"
                  name="key"
                  id="key"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.datosBancoEmpleado.cuentaClabe"
                  required
                  maxlength="18"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="nomina">Anticipo de nomina</label>
                <select
                  name="nomina"
                  id="nomina"
                  v-model="dataUser.datosBancoEmpleado.anticipoNomina"
                >
                  <option selected disabled value="0">
                    Selecciona anticipo
                  </option>
                  <option value="1">Si</option>
                  <option value="2">No</option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresardataBank()">
                Regresar
              </button>
              <button class="botonAmarilloDerecho" @click="validacionBank()">
                Guardar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="base" v-if="dataCfdi">
      <v-row no-gutters class="titulo ml-10 mt-5">DATOS PARA CFDI</v-row>
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formulariosTargetL"
          >
            <v-row>
              <div class="form-group">
                <label for="labor">Antigüedad</label>
                <input
                  type="text"
                  name="labor"
                  id="labor"
                  placeholder="Obligatorio"
                  class="inputs mr-2"
                  autocomplete="false"
                  v-model="dataUser.cfdiEmpleado.antiguedad"
                  required
                  maxlength="1"
                  @keypress="isNumber($event)"
                />Años
              </div>
              <div class="form-group">
                <label for="typeContract">Tipo de Contrato</label>
                <select
                  name="typeContract"
                  id="typeContract"
                  v-model="dataUser.cfdiEmpleado.contratoId"
                >
                  <option selected disabled value="0">
                    Selecciona Contrato
                  </option>
                  <option
                    v-for="contract in contracts"
                    :key="contract.id"
                    v-bind:value="contract.id"
                  >
                    {{ contract.tipoContrato }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="typeDay">Tipo de Jornada</label>
                <select
                  name="typeDay"
                  id="typeDay"
                  v-model="dataUser.cfdiEmpleado.jornadaId"
                >
                  <option disabled value="0">Selecciona Jornada</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.nombreJornada }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="risk">Riesgo del Puesto</label>
                <select
                  name="risk"
                  id="risk"
                  v-model="dataUser.cfdiEmpleado.riesgoId"
                >
                  <option disabled value="0">Selecciona Riesgo</option>
                  <option
                    v-for="risk in risks"
                    :key="risk.id"
                    v-bind:value="risk.id"
                  >
                    {{ risk.nombreRiesgo }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="union">Sindicalizado</label>
                <select
                  name="union"
                  id="union"
                  v-model="dataUser.cfdiEmpleado.sindicalizado"
                >
                  <option disabled value="0">Selecciona Sindicalizado</option>
                  <option selected value="1">Si</option>
                  <option value="2">No</option>
                </select>
              </div>
              <div class="form-group">
                <label for="regime">Tipo de Régimen</label>
                <select
                  name="regime"
                  id="regime"
                  v-model="dataUser.cfdiEmpleado.regimenId"
                >
                  <option disabled value="0">Selecciona Regimen</option>
                  <option
                    v-for="regime in regimes"
                    :key="regime.id"
                    v-bind:value="regime.id"
                  >
                    {{ regime.nombreRegimen }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label for="periodicity">Periodicidad de pago</label>
                <select
                  name="periodicity"
                  id="periodicity"
                  v-model="dataUser.cfdiEmpleado.periodicidadPagoId"
                >
                  <option disabled value="0">Selecciona Periodicidad</option>
                  <option
                    v-for="periodicity in periodicities"
                    :key="periodicity.id"
                    v-bind:value="periodicity.id"
                  >
                    {{ periodicity.nombrePeriodicidad }}
                  </option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="cancelar()">
                Regresar
              </button>
              <button class="botonAmarilloDerecho" @click="validacionCfdi()">
                Siguiente
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="cancel()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      jobs: [],
      areas: [],
      //Arrays para CFDI
      contracts: [],
      days: [],
      risks: [],
      regimes: [],
      periodicities: [], //Arrays para empleado
      advance: "",
      errors: [],
      error: false,
      respuesta: "",
      dataUser: [],
      user: true,
      dataBank: false,
      dataCfdi: false,
      banks: [],
      branches: [],
      titulo: "",
      confirmation: false,
      telefonoSucursal: null,
      advice: false,
      errorCurp: false,
      enterprise: 0,
      branch: 0,
      auxName: "",
      auxSurname: "",
      auxLastName: "",
      genders: [],
    };
  },
  methods: {
    numeroSucursal() {
      let suc = this.branches.find(
        (sucursal) => sucursal.id === this.dataUser.sucursalId
      );
      this.telefonoSucursal = suc.telefono;
      this.dataUser.ext = "";
    },
    listarGeneros() {
      this.show = true;
      axios
        .get(Server + "/genero", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.genders = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarJornadas() {
      this.show = true;
      axios
        .get(Server + "/jornadas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.days = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/riesgosPuesto", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.risks = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarBancos() {
      this.show = true;
      axios
        .get(Server + "/bancos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.banks = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarContratos() {
      this.show = true;
      axios
        .get(Server + "/contratos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.contracts = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listaRegimen() {
      this.show = true;
      axios
        .get(Server + "/regimen", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.regimes = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listaPeriodicidad() {
      this.show = true;
      axios
        .get(Server + "/periodicidadesPago", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.periodicities = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    answer() {
      this.dataUser.rfc = "";
      if (this.auxSurname == "") {
        this.dataUser.rfc += this.auxLastName.substr(0, 1);
        var l = this.auxLastName.length;
        var c;
        if (l > 1) {
          for (var i = 1; i < l; i++) {
            c = this.auxLastName.charAt(i);
            if (this.vocales(c)) {
              this.dataUser.rfc += c;
              break;
            }
          }
          this.dataUser.rfc += this.auxName.substr(0, 2);
        } else {
          c = "X";
          this.dataUser.rfc += c;
          this.dataUser.rfc += this.auxName.substr(0, 2);
        }
      } else if (this.auxLastName == "") {
        this.dataUser.rfc += this.auxSurname.substr(0, 1);
        var l = this.auxSurname.length;
        var c;
        if (l > 1) {
          for (var i = 1; i < l; i++) {
            c = this.auxSurname.charAt(i);
            if (this.vocales(c)) {
              this.dataUser.rfc += c;
              break;
            }
          }
          // rfc += apellidoMaterno.substr(0, 1);
          this.dataUser.rfc += this.auxName.substr(0, 2);
        } else {
          c = "X";
          this.dataUser.rfc += c;
          this.dataUser.rfc += this.auxName.substr(0, 2);
        }
      } else {
        this.dataUser.rfc += this.auxSurname.substr(0, 1);
        var l = this.auxSurname.length;
        var c;
        if (l > 1) {
          for (var i = 1; i < l; i++) {
            c = this.auxSurname.charAt(i);
            if (this.vocales(c)) {
              this.dataUser.rfc += c;
              break;
            }
          }
          this.dataUser.rfc += this.auxLastName.substr(0, 1);
          this.dataUser.rfc += this.auxName.substr(0, 1);
        } else {
          this.dataUser.rfc += this.auxLastName.substr(0, 1);
          this.dataUser.rfc += this.auxName.substr(0, 2);
        }
      }
      this.dataUser.rfc += this.dataUser.fechaNacimiento.substr(2, 2);
      this.dataUser.rfc += this.dataUser.fechaNacimiento.substr(5, 2);
      this.dataUser.rfc += this.dataUser.fechaNacimiento.substr(8, 2);
      this.dataUser.rfc = this.palabraInconveniente(this.dataUser.rfc);
      this.dataUser.curp = this.dataUser.rfc;
      console.log("RFC CREADO" + this.dataUser.rfc);
    },
    quitaArticulos(nombre) {
      this.auxName = nombre;
      console.log("quitar ARTICULOS nombre compuesto" + this.auxName);
      this.auxName = this.auxName
        .replace("LA ", "")
        .replace("DE ", "")
        .replace("SA DE CV", "")
        .replace("LOS ", "")
        .replace("LAS ", "")
        .replace("Y ", "")
        .replace("MC ", "")
        .replace("SA ", "")
        .replace("VON ", "")
        .replace("CIA ", "")
        .replace("DEL ", "")
        .replace("SOC ", "")
        .replace("COOP ", "")
        .replace("A EN P ", "")
        .replace("MAC ", "")
        .replace("S EN C ", "")
        .replace("VAN ", "")
        .replace("EN ", "")
        .replace("MI ", "")
        .replace("CON ", "")
        .replace("SUS ", "")
        .replace("SC ", "")
        .replace("SCS ", "")
        .replace("THE ", "")
        .replace("AND ", "")
        .replace("CO ", "")
        .replace("A ", "")
        .replace("SA DE CV MI ", "")
        .replace("COMPA&ÍA ", "")
        .replace("SRL MI", "");
      console.log("sin articulos" + this.auxName);
      return this.auxName;
    },
    quitaArticulosPaterno() {
      this.auxSurname = this.dataUser.apellidoPaterno.toUpperCase();
      console.log("quitar ARTICULOS apellido Paterno" + this.auxSurname);
      this.auxSurname = this.auxSurname
        .replace("LA ", "")
        .replace("DE ", "")
        .replace("SA DE CV", "")
        .replace("LOS ", "")
        .replace("LAS ", "")
        .replace("Y ", "")
        .replace("MC ", "")
        .replace("SA ", "")
        .replace("VON ", "")
        .replace("CIA ", "")
        .replace("DEL ", "")
        .replace("SOC ", "")
        .replace("COOP ", "")
        .replace("A EN P ", "")
        .replace("MAC ", "")
        .replace("S EN C ", "")
        .replace("VAN ", "")
        .replace("EN ", "")
        .replace("MI ", "")
        .replace("CON ", "")
        .replace("SUS ", "")
        .replace("SC ", "")
        .replace("SCS ", "")
        .replace("THE ", "")
        .replace("AND ", "")
        .replace("CO ", "")
        .replace("A ", "")
        .replace("SA DE CV MI ", "")
        .replace("COMPA&ÍA ", "")
        .replace("SRL MI", "");
      console.log("sin articulos" + this.auxSurname);
      return this.auxSurname;
    },
    quitaArticulosMaterno() {
      this.auxLastName = this.dataUser.apellidoMaterno.toUpperCase();
      console.log("quitar ARTICULOS apellido Materno" + this.auxSurname);
      this.auxLastName = this.auxLastName
        .replace("LA ", "")
        .replace("DE ", "")
        .replace("SA DE CV", "")
        .replace("LOS ", "")
        .replace("LAS ", "")
        .replace("Y ", "")
        .replace("MC ", "")
        .replace("SA ", "")
        .replace("VON ", "")
        .replace("CIA ", "")
        .replace("DEL ", "")
        .replace("SOC ", "")
        .replace("COOP ", "")
        .replace("A EN P ", "")
        .replace("MAC ", "")
        .replace("S EN C ", "")
        .replace("VAN ", "")
        .replace("EN ", "")
        .replace("MI ", "")
        .replace("CON ", "")
        .replace("SUS ", "")
        .replace("SC ", "")
        .replace("SCS ", "")
        .replace("THE ", "")
        .replace("AND ", "")
        .replace("CO ", "")
        .replace("A ", "")
        .replace("SA DE CV MI ", "")
        .replace("COMPA&ÍA ", "")
        .replace("SRL MI", "");
      console.log("sin articulos" + this.auxLastName);
      return this.auxLastName;
    },
    vocales(letra) {
      if (
        letra == "A" ||
        letra == "E" ||
        letra == "I" ||
        letra == "O" ||
        letra == "U" ||
        letra == "a" ||
        letra == "e" ||
        letra == "i" ||
        letra == "o" ||
        letra == "u"
      )
        return true;
      else return false;
    },
    palabraInconveniente(palabra) {
      palabra = palabra
        .replace("BUEI", "BUEX")
        .replace("KOGE", "KOGX")
        .replace("BUEY", "BUEX")
        .replace("KOJO", "KOJX")
        .replace("CACA", "CACX")
        .replace("KAKA", "KAKX")
        .replace("CACO", "CACX")
        .replace("KULO", "KULX")
        .replace("CAGA", "CAGX")
        .replace("MAME", "MAMX")
        .replace("CAGO", "CAGX")
        .replace("MAMO", "MAMX")
        .replace("CAKA", "CAKX")
        .replace("MEAR", "MEAX")
        .replace("COGE", "COGX")
        .replace("MEON", "MEOX")
        .replace("COJA", "COJX")
        .replace("MION", "MIOX")
        .replace("COJE", "COJX")
        .replace("MOCO", "MOCX")
        .replace("COJI", "COJX")
        .replace("MULA", "MULX")
        .replace("COJO", "COJX")
        .replace("PEDA", "PEDX")
        .replace("CULO", "CULX")
        .replace("PEDO", "PEDX")
        .replace("FETO", "FETX")
        .replace("PENE", "PENX")
        .replace("GUEY", "GUEX")
        .replace("PUTA", "PUTX")
        .replace("JOTO", "JOTX")
        .replace("PUTO", "PUTX")
        .replace("KACA", "KACX")
        .replace("QULO", "QULX")
        .replace("KACO", "KACX")
        .replace("RATA", "RATX")
        .replace("KAGA", "KAGX")
        .replace("RUIN", "RUIX")
        .replace("KAGO", "KAGX");
      return palabra;
    },
    anexo1(letra) {
      letra = letra.toUpperCase();
      letra = letra
        .replace(" ", "00")
        .replace("0", "00")
        .replace("1", "01")
        .replace("2", "02")
        .replace("3", "03")
        .replace("4", "04")
        .replace("5", "05")
        .replace("6", "06")
        .replace("7", "07")
        .replace("8", "08")
        .replace("9", "09")
        .replace("&", "10")
        .replace("A", "11")
        .replace("B", "12")
        .replace("C", "13")
        .replace("D", "14")
        .replace("E", "15")
        .replace("F", "16")
        .replace("G", "17")
        .replace("H", "18")
        .replace("I", "19")
        .replace("J", "21")
        .replace("K", "22")
        .replace("L", "23")
        .replace("M", "24")
        .replace("N", "25")
        .replace("O", "26")
        .replace("P", "27")
        .replace("Q", "28")
        .replace("R", "29")
        .replace("S", "32")
        .replace("T", "33")
        .replace("U", "34")
        .replace("V", "35")
        .replace("W", "36")
        .replace("X", "37")
        .replace("Y", "38")
        .replace("Z", "39")
        .replace("Ñ", "40");
      return letra;
    },
    nombreCompuesto() {
      console.log("LLEGA DIRECTO" + this.dataUser.nombre);
      let aux = this.dataUser.nombre.toUpperCase();
      aux = aux.replace("MARIA ", "").replace("JOSE ", "");
      console.log("NOMBRE COMPUESTO" + aux);
      return this.quitaArticulos(aux);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validaRfc() {
      console.log("llegue a " + this.dataUser.rfc);
      var strCorrecta;
      strCorrecta = this.dataUser.rfc;
      if (this.dataUser.rfc.length == 13) {
        console.log(this.dataUser.rfc.length);
        var valid =
          "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      } else {
        this.errors.push("El RFC esta incompleto favor de verificarlo.");
      }
      var validRfc = new RegExp(valid);
      var matchArray = strCorrecta.match(validRfc);
      if (matchArray == null) {
        this.errors.push("El RFC es incorrecto favor de verificarlo.");
        return false;
      } else {
        console.log("Correcto");
        return true;
      }
    },
    validaImss() {
      console.log("llegue a nss" + this.dataUser.nss);
      if (this.dataUser.nss.length == 11) {
        return false;
      } else {
        this.errors.push("Número de IMSS incorrecto");
        return false;
      }
    },
    validarEmail() {
      //console.log("entro a Validar Email");
      var exp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.dataUser.email != "") {
        if (exp.test(this.dataUser.email)) {
          //console.log("El correo esta bien");
          return false;
        } else {
          this.errors.push("Se debe especificar un correo electrónico válido.");
          //console.log("El correo no esta bien");
          return true;
        }
      }
    },
    validacion() {
      this.errors = [];
      this.validaCurp();
      this.validaRfc();
      this.validaImss();
      this.validarEmail();
      this.titulo = "Empleado";
      if (this.dataUser.id == "" || this.dataUser.id == 0) {
        this.errors.push("Se debe especificar el número de empleado.");
      }
      if (this.dataUser.nombre == "" || this.dataUser.nombre == " ") {
        this.errors.push("Se debe especificar el nombre del empleado.");
      }
      if (
        this.dataUser.apellidoPaterno == "" ||
        this.dataUser.apellidoPaterno == " "
      ) {
        this.errors.push("Se debe especificar el apellido paterno.");
      }
      if (this.dataUser.fechaNacimiento == "") {
        this.errors.push("Se debe especificar la fecha de nacimiento.");
      }
      if (
        this.dataUser.telefonoCelular == "" ||
        this.dataUser.telefonoCelular == null || this.dataUser.telefonoCelular.length > 10
      ) {
        this.errors.push("Se debe ingresar un número telefónico valido.");
      }
      if (this.dataUser.puestoId == 0 || this.dataUser.puestoId == "") {
        this.errors.push("Se debe especificar el puesto.");
      }
      if (this.dataUser.areaId == "" || this.dataUser.areaId == 0) {
        this.errors.push("Se debe especificar el area.");
      }
      if (this.dateAdmission == "") {
        this.errors.push("Se debe especificar la fecha de admisión.");
      }
      if (this.pay == "" && this.pay == 0) {
        this.errors.push("Se debe especificar el sueldo.");
      }
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la actualización del empleado.";
        this.continuar();
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    cancelar() {
      this.dataCfdi = false;
      this.user = true;
    },
    cancel() {
      localStorage.employeeNumber = "";
      this.$router.push("/employee");
    },
    validacionCfdi() {
      this.errors = [];
      this.titulo = "CFDI";
      if (
        this.dataUser.cfdiEmpleado.antiguedad == "" ||
        this.dataUser.cfdiEmpleado.antiguedad == " "
      ) {
        this.errors.push("Se debe especificar la antigüedad.");
      }
      if (
        this.dataUser.cfdiEmpleado.contratoId == "" ||
        this.dataUser.cfdiEmpleado.contratoId == 0
      ) {
        this.errors.push("Se debe especificar el tipo de contrato.");
      }
      if (
        this.dataUser.cfdiEmpleado.jornadaId == "" ||
        this.dataUser.cfdiEmpleado.jornadaId == 0
      ) {
        this.errors.push("Se debe especificar el tipo de jornada.");
      }
      if (
        this.dataUser.cfdiEmpleado.riesgoId == "" ||
        this.dataUser.cfdiEmpleado.riesgoId == 0
      ) {
        this.errors.push("Se debe especificar el riesgo del puesto.");
      }
      if (
        this.dataUser.cfdiEmpleado.sindicalizado == "" ||
        this.dataUser.cfdiEmpleado.sindicalizado == 0
      ) {
        this.errors.push("Se debe especificar el sindicato.");
      }
      if (
        this.dataUser.cfdiEmpleado.idRegimen == 0 ||
        this.dataUser.cfdiEmpleado.idRegimen == ""
      ) {
        this.errors.push("Se debe especificar el régimen.");
      }
      if (
        this.dataUser.cfdiEmpleado.idPeriodicidadPago == "" ||
        this.dataUser.cfdiEmpleado.idPeriodicidadPago == 0
      ) {
        this.errors.push("Se debe especificar la periodicidad del pago.");
      }
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la Actualizar el empleado.";
        this.continuarData();
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    continuarData() {
      this.dataCfdi = false;
      this.dataBank = true;
    },

    validaCount() {
      console.log(this.dataUser.datosBancoEmpleado.cuenta);
      var count = this.dataUser.datosBancoEmpleado.cuenta;
      if (count.length != 10) {
        this.errors.push("Número de Cuenta incorrecto");
        return false;
      } else {
        console.log("Correcto");
        console.log(count.length);
        return false;
      }
    },
    validaKey() {
      console.log(this.dataUser.datosBancoEmpleado.cuentaClabe);
      var key = this.dataUser.datosBancoEmpleado.cuentaClabe;
      if (key.length === 18) {
        return true;
      } else {
        this.errors.push("Número de Cuenta Clave incorrecto.");
        return false;
      }
    },
    validacionBank() {
      this.errors = [];
      this.titulo = "Datos Bancarios";
      this.validaCount();
      this.validaKey();
      if (
        this.dataUser.datosBancoEmpleado.bancoId == 0 ||
        this.dataUser.datosBancoEmpleado.bancoId == ""
      ) {
        this.errors.push("Se debe especificar el Banco.");
      }
      if (
        this.dataUser.datosBancoEmpleado.anticipoNomina == 0 ||
        this.dataUser.datosBancoEmpleado.anticipoNomina == ""
      ) {
        this.errors.push("Se debe especificar si cuenta con anticipo.");
      }
      if (
        this.dataUser.datosBancoEmpleado.cuenta == 0 ||
        this.dataUser.datosBancoEmpleado.cuenta == " " ||
        this.dataUser.datosBancoEmpleado.cuenta == ""
      ) {
        this.errors.push("Se debe especificar el número de cuenta.");
      }
      if (
        this.dataUser.datosBancoEmpleado.cuentaClabe == 0 ||
        this.dataUser.datosBancoEmpleado.cuentaClabe == " " ||
        this.dataUser.datosBancoEmpleado.cuentaClabe == ""
      ) {
        this.errors.push("Se debe especificar cuenta clabe.");
      }
      if (this.errors.length == 0) {
        this.titulo = "Alerta";
        this.respuesta = "Esta seguro de la actualizar el empleado.";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    validaCurp() {
      var strCorrecta = this.dataUser.curp;
      if (strCorrecta.length === 18) {
        return true;
      } else {
        this.errors.push("El CURP esta incompleto favor de verificarlo.");
        return false;
      }
    },
    continuar() {
      this.user = false;
      this.dataBank = false;
      this.dataCfdi = true;
    },
    regresar() {
      localStorage.employeeNumber = "";
      this.$router.push("/employee");
    },
    regresardataBank() {
      this.dataCfdi = true;
      this.dataBank = false;
    },
    recuperar() {
      this.show = true;
      axios
        .get(Server + "/empleados/" + localStorage.employeeNumber, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.dataUser = response.data;
          localStorage.employeeNumber = "";
          this.enterprise = this.dataUser.empresaId;
          this.format_date(
            this.dataUser.fechaNacimiento,
            this.dataUser.fechaAdmision
          );
          this.telefonoSucursal = this.dataUser.sucursal != null ? this.dataUser.sucursal.telefono : null;
          this.obtenerAreasPuestos();
          console.log("datUser", this.dataUser);
          if (this.dataUser.cfdiEmpleado == null) {
            this.dataUser.cfdiEmpleado = {
              id: 0,
              antiguedad: "0",
              contratoId: 0,
              jornadaId: 0,
              riesgoId: 0,
              sindicalizado: false,
              regimenId: 0,
              periodicidadPagoId: 0,
            };
          }
          if (this.dataUser.datosBancoEmpleado == null) {
            this.dataUser.datosBancoEmpleado = {
              id: 0,
              bancoId: 0,
              cuenta: "",
              cuentaClabe: "",
              anticipoNomina: false,
            };
          }
          if (this.dataUser.cfdiEmpleado.sindicalizado === false) {
            this.dataUser.cfdiEmpleado.sindicalizado = 2;
          } else {
            this.dataUser.cfdiEmpleado.sindicalizado = 1;
          }
          if (this.dataUser.datosBancoEmpleado.anticipoNomina === false) {
            this.dataUser.datosBancoEmpleado.anticipoNomina = 2;
          } else {
            this.dataUser.datosBancoEmpleado.anticipoNomina = 1;
          }
          console.log("datUser2", this.dataUser);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .put(
          Server + "/empleados/" + this.dataUser.id,
          {
            EmpresaId: this.enterprise,
            Nombre: this.dataUser.nombre,
            ApellidoPaterno: this.dataUser.apellidoPaterno,
            ApellidoMaterno: this.dataUser.apellidoMaterno,
            FechaNacimiento: this.dataUser.fechaNacimiento,
            GeneroId: this.dataUser.generoId,
            CURP: this.dataUser.curp,
            RFC: this.dataUser.rfc,
            TelefonoCelular: this.dataUser.telefonoCelular,           
            TelefonoExtension: this.dataUser.telefonoExtension,
            Email: this.dataUser.email,
            NSS: this.dataUser.nss,
            PuestoId: this.dataUser.puestoId,
            AreaId: this.dataUser.areaId,
            SucursalId: this.dataUser.sucursalId,
            FechaAdmision: this.dataUser.fechaAdmision,
            Sueldo: parseFloat(this.dataUser.sueldo),
            //data CFDI
            Antiguedad: this.dataUser.cfdiEmpleado.antiguedad,
            ContratoId: this.dataUser.cfdiEmpleado.contratoId,
            JornadaId: this.dataUser.cfdiEmpleado.jornadaId,
            RiesgoId: this.dataUser.cfdiEmpleado.riesgoId,
            Sindicalizado:
              this.dataUser.cfdiEmpleado.sindicalizado == "1" ? true : false,
            IdRegimen: this.dataUser.cfdiEmpleado.regimenId,
            IdPeriodicidadPago: this.dataUser.cfdiEmpleado.periodicidadPagoId,
            //data Bank
            BancoId: this.dataUser.datosBancoEmpleado.bancoId, //this.bank,
            Cuenta: this.dataUser.datosBancoEmpleado.cuenta,
            CuentaClabe: this.dataUser.datosBancoEmpleado.cuentaClabe,
            AnticipoNomina:
              this.dataUser.datosBancoEmpleado.anticipoNomina == "1"
                ? true
                : false,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.enterprise = "";
          this.message = "Confirmación";
          this.respuesta = "El Empleado fue actualizado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.enterprise = "";
            this.message = "Aviso";
            this.respuesta = "El Empleado no fue actualizado.";
            this.confirmation = true;
          }
        });
    },
    aux() {
      localStorage.employeeNumber = "";
      this.$router.push("/employee");
    },
    format_date(fecha1, fecha2) {
      console.log("fecha pasada" + fecha1);
      var aux = fecha1.substr(0, 10);
      console.log("fecha obtenida" + aux);
      var aux2 = fecha2.substr(0, 10);
      this.dataUser.fechaNacimiento = aux;
      this.dataUser.fechaAdmision = aux2;
      return true;
    },
    obtenerAreasPuestos() {
      console.log("Entro a obtener los puestos");
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.jobs = [];
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.branches = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.branches = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listarGeneros();
    this.listarJornadas();
    this.listarContratos();
    this.listaRegimen();
    this.listaPeriodicidad();
    this.recuperar();
    this.listarBancos();
  },
};
</script>